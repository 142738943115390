import React from 'react'
import { graphql} from 'gatsby'
import Layout from "../components/layout" 
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { INLINES, BLOCKS} from "@contentful/rich-text-types"
import './publications.css'
import SEO from "../components/seo"


export default function Publications(props) {

    const publications = props.data.allContentfulPublication.edges
    const options = {
      renderNode: {
        [INLINES.ASSET_HYPERLINK]: (node, children) => {
          return <a href={node.data.target.file.url} target="_blank" rel="noreferrer" className="">{children}</a>
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
          return <img src={node.data.target.file.url} alt=""/>
        },
        [INLINES.HYPERLINK]: (node, children) => {
          return <a href={node.data.uri} target="_blank" rel="noreferrer">{children}</a>;
        }
    }
  }
   

    const renderPublications = () => {
        
        publications.sort((a, b) => {
            return b.node.order - a.node.order ;
        });

    return publications.map(p=> {
            const {squareSpaceLink, cover} = p.node

      return <div className="publication-item-cont" key={cover.order}>
                    <div className="flex1">
                      {squareSpaceLink != null?
                          <a href={`${squareSpaceLink}`} target="_blank" rel="noreferrer">
                              <GatsbyImage image={getImage(cover.photo)} alt={cover.photo.description} loading="eager" key={cover.order}/>
                          </a>: 
                          <GatsbyImage image={getImage(cover.photo)} alt={cover.photo.description} loading="eager" key={cover.order}/>}         
                    </div>
                    <div className="flex2">
                      {/* {console.log(cover.publication[0].publicationInformation === undefined ? "true":"false")} */}
                      {/* console.log(cover) */}
                      {cover.publication && renderRichText(cover.publication[0].publicationInformation, options)}
                    </div>
              </div>
              }    
            ) 
          }
    return (
        <Layout>
          <SEO 
            title={`Publications`}
            description={`Publications by artist ${publications.map(p=> {return p.node.artist })}`}
          />
            <h1 className="visibility-hidden">Publications</h1>
            <div className="publications-cont">
                {renderPublications()}
            </div>
        </Layout>
    )
}

export const pageQuery = graphql
`
query publicationQuery {
  allContentfulPublication {
    edges {
      node {
        artist
        title
        order
        squareSpaceLink
        cover {
          photo {
            description
            gatsbyImageData(
              quality: 60 
              width: 700
              )
          }
          publication {
            publicationInformation {
              raw
            }
          }
        }
      }
    }
  }
}
  
`
